import { IconProps } from '../../ui/Icons';

type ImageProps = IconProps;

export const GreenArchImage = (props: ImageProps): JSX.Element => (
  <svg
    className={props.className}
    width={props.width ?? 519}
    height={props.height ?? 258}
    style={props.style}
    viewBox="0 0 519 258"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g style={{ mixBlendMode: 'multiply' }} opacity="0.7">
      <path
        d="M42.8107 220.022C15.2103 195.68 0.00901995 163.302 0.00901995 128.859H-1.31726e-05C-1.31726e-05 94.4169 15.2012 62.039 42.8017 37.697C70.3661 13.3879 107.004 4.23726e-07 145.975 0L517.808 0.55816L517.794 8.76654L145.971 8.20838C109.364 8.20838 74.9594 20.7754 49.0871 43.5906C23.2508 66.3771 9.0242 96.6619 9.0242 128.863C9.0242 161.065 23.2553 191.35 49.0871 214.132C74.9594 236.947 109.369 249.514 145.971 249.514L517.803 249.252L517.812 257.46L145.975 257.723C107.013 257.723 70.3751 244.335 42.8107 220.022Z"
        fill="currentColor"
      />
      <path
        d="M60.6991 205.789C37.2594 185.226 24.3512 157.81 24.3512 128.591H24.3466C24.3466 99.4247 37.2053 72.0124 60.5502 51.4008C83.8591 30.8262 114.835 19.4916 147.783 19.4916L516.616 19.5327V27.7461L147.783 27.7051C117.194 27.7051 88.446 38.2141 66.8285 57.298C45.2516 76.3491 33.3671 101.626 33.3671 128.591C33.3671 155.555 45.2967 180.873 66.9548 199.875C88.7166 218.963 117.808 229.476 148.87 229.476L516.616 229.107L516.625 237.32L148.874 237.69C115.462 237.69 84.1478 226.359 60.6991 205.789Z"
        fill="currentColor"
      />
      <path
        d="M48.0995 128.32C48.0995 152.117 58.6331 174.432 77.7597 191.164C96.8952 207.9 122.471 217.115 149.784 217.115L517.214 217.054V208.848L149.784 208.909C124.822 208.909 101.467 200.506 84.0193 185.248C66.6755 170.079 57.1258 149.86 57.1258 128.32C57.1258 83.8813 98.204 47.7305 148.692 47.7305L517.21 48.0793L517.219 39.8735L148.697 39.5247C121.848 39.5247 96.6064 48.7439 77.6107 65.4879C58.5789 82.2647 48.0995 104.576 48.0995 128.32Z"
        fill="currentColor"
      />
      <path
        d="M95.5566 177.463C80.6528 164.464 72.4468 147.11 72.4462 128.591V128.593V128.589V128.591C72.4474 90.5259 107.532 59.5577 150.656 59.5577L518.995 59.7957L518.995 67.9995L150.656 67.7574C112.51 67.7574 81.4782 95.0474 81.4782 128.589C81.4782 144.853 88.6957 160.108 101.808 171.544C115.024 183.07 132.759 189.42 151.744 189.42L519 189.247V197.451L151.744 197.623C130.416 197.623 110.461 190.466 95.5566 177.463Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export const YellowCircleImage = (props: ImageProps): JSX.Element => (
  <svg
    viewBox="0 0 206 195"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={props.className}
    width={props.width ?? 206}
    height={props.height ?? 195}
    style={props.style}
  >
    <g opacity="0.5">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.168 56.7353C78.4396 56.7353 59.2043 75.0839 59.2043 97.718C59.2043 120.352 78.4396 138.701 102.168 138.701C125.895 138.701 145.131 120.352 145.131 97.718C145.131 75.0839 125.895 56.7353 102.168 56.7353ZM68.1643 97.718C68.1643 79.8042 83.388 65.2823 102.168 65.2823C120.947 65.2823 136.171 79.8042 136.171 97.718C136.171 115.632 120.947 130.154 102.168 130.154C83.3881 130.154 68.1643 115.632 68.1643 97.718Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.376 97.7183C38.376 64.1101 66.9375 36.8653 102.17 36.8653C137.402 36.8653 165.964 64.1101 165.964 97.7183C165.964 131.327 137.402 158.571 102.17 158.571C66.9375 158.571 38.376 131.327 38.376 97.7183ZM102.17 45.4203C71.8906 45.4203 47.3444 68.8349 47.3444 97.7183C47.3444 126.602 71.8906 150.016 102.17 150.016C132.449 150.016 156.995 126.602 156.995 97.7183C156.995 68.8349 132.449 45.4203 102.17 45.4203Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.82 18.2364C57.1621 18.2364 20.1487 53.8214 20.1487 97.7178C20.1487 141.614 57.1621 177.199 102.82 177.199C148.479 177.199 185.492 141.614 185.492 97.7178C185.492 53.8214 148.479 18.2364 102.82 18.2364ZM29.1308 97.7178C29.1308 58.5907 62.1227 26.8718 102.82 26.8718C143.518 26.8718 176.51 58.5907 176.51 97.7178C176.51 136.845 143.518 168.564 102.82 168.564C62.1227 168.564 29.1308 136.845 29.1308 97.7178Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.82 0.849609C46.3765 0.849609 0.619873 44.2189 0.619873 97.7177C0.619873 151.217 46.3765 194.586 102.82 194.586C159.264 194.586 205.021 151.217 205.021 97.7177C205.021 44.2189 159.264 0.849609 102.82 0.849609ZM9.63189 97.7177C9.63189 48.9365 51.3537 9.39143 102.82 9.39143C154.287 9.39143 196.009 48.9365 196.009 97.7177C196.009 146.499 154.287 186.044 102.82 186.044C51.3537 186.044 9.63189 146.499 9.63189 97.7177Z"
        fill="currentColor"
      />
    </g>
  </svg>
);
