import { createContext, useContext } from 'react';

type HeroColorContext = {
  /** The current color to animate to, if null, the default color will be used */
  currentColor: string | null;
  /** Setter for the current color, setting null will reset the color to the default value */
  setCurrentColor: (newColor: string | null) => void;
};

const context = createContext<HeroColorContext>({ currentColor: null, setCurrentColor: () => {} });

export const HeroColorProvider = context.Provider;

export function useHeroColor(): HeroColorContext {
  const currentContext = useContext(context);

  return currentContext;
}
