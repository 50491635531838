import type { SerializableResult } from '@/types';
import { useState } from 'react';
import { useMedia } from 'react-use';

import { btnStyles } from '@/styles/buttons.css';
import { breakpoints } from '@/styles/util';

import { ChevronDownIcon } from '@/ui/Icons';
import ExternalLink from '@/ui/Link/ExternalLink';

import * as PaginatedHorizontalScroll from '../PaginatedHorizontalScroll';
import { ErrorFallback } from '../ErrorFallback';
import {
  buttonClass,
  containerClass,
  paginationButtonsContainerClass,
  headerClass,
  headerTextClass,
  iconNextClass,
  iconPreviousClass,
  paginationButtonsClass,
  scrollerClass,
  allArticlesLinkContainerClass,
} from './index.css';
import { PostPreview } from './PostPreview';
import type { BlogPost } from './types';

interface RecentBlogPostsProps {
  /** Serialized blog posts response */
  posts: SerializableResult<BlogPost[]>;
}

/**
 * Component for displaying recent blog posts
 */
export const RecentBlogPosts = ({ posts }: RecentBlogPostsProps): JSX.Element => {
  // state
  const [page, setPage] = useState<number>(1);
  const isDesktop = useMedia(breakpoints.desktop, true);
  const isTablet = useMedia(breakpoints.tablet, false);
  const isMobile = useMedia(breakpoints.mobile, false);

  // failure modes
  if (posts.type === 'error' || !posts.data.length)
    return <ErrorFallback componentName="recent blog posts" />;

  // variables
  const columns = isDesktop ? 4 : isTablet ? 3 : 2;
  const items = posts.data.map(({ id, ...post }) => ({ id, node: <PostPreview {...post} /> }));

  // render
  return (
    <div className={containerClass}>
      <div className={headerClass}>
        <h1 className={headerTextClass}>From Matcha blog</h1>

        <div className={paginationButtonsContainerClass}>
          <div className={paginationButtonsClass}>
            <button
              aria-label="previous page"
              className={buttonClass}
              disabled={page <= 1}
              onClick={() => setPage(page - 1)}
            >
              <ChevronDownIcon className={iconPreviousClass} />
            </button>

            <button
              aria-label="next page"
              className={buttonClass}
              disabled={page >= Math.ceil(items.length / columns)}
              onClick={() => setPage(page + 1)}
            >
              <ChevronDownIcon className={iconNextClass} />
            </button>
          </div>
        </div>
      </div>

      <PaginatedHorizontalScroll.Root
        className={scrollerClass}
        columns={columns}
        isHorizontalScroll={isMobile}
        page={isMobile ? undefined : page}
      >
        <PaginatedHorizontalScroll.List items={items} />
      </PaginatedHorizontalScroll.Root>

      <div className={allArticlesLinkContainerClass}>
        <ExternalLink
          className={btnStyles.SECONDARY_PILL_SPECIAL}
          href="https://blog.matcha.xyz/categories"
        >
          See all articles
        </ExternalLink>
      </div>
    </div>
  );
};
