import { HTMLProps, ReactNode, forwardRef } from 'react';

import { cardBaseClass } from './index.css';

type Props = Omit<HTMLProps<HTMLDivElement>, 'children'> & { children: ReactNode };

export const GenericCard = forwardRef<HTMLDivElement, Props>(function GenericCard(
  { children, className, ...props },
  ref,
) {
  return (
    <div className={`${cardBaseClass} ${className ? className : ''}`} ref={ref} {...props}>
      {children}
    </div>
  );
});
