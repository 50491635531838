import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import Link from 'next/link';
import { HTMLProps, MouseEventHandler, PropsWithoutRef, forwardRef, useEffect } from 'react';

import { ClientInputError } from '@/lib/errors/client';

import { useHeroColor } from '@/hooks/homepage/useHeroColor';

import { ImageChainOverlayIcon } from '@/ui/Icons/TokenChainIcon';

import { trackError } from '@/utils/errors';

import { NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID } from '../../../constants/chain';
import { percentageFormatter, usdFormatter } from '../../../lib/formatting';
import {
  EVENT_NAME,
  HOMEPAGE_CLICK_CARD_TYPE,
  HOMEPAGE_TOKEN_CARD_LOADED_TYPE,
  logEvent,
} from '../../../utils/amplitude';
import { routes } from '../../../utils/routes';
import { truncate } from '../../../utils/string';
import { AvatarSkeleton, TextSkeleton } from '../../Skeleton';
import { GenericCard } from '../GenericCard';
import {
  chainIndicator,
  loadingTextClass,
  lineSkeletonSpacer,
  tokenCardWrapperClass,
  titleLoadingClass,
  loadingTokenChainOverlay,
  headerWrapper,
  tokenNameClass,
  tokenSymbolClass,
  tokenChainOverlayOverrideClass,
  headerTextWrapper,
  usdValueClass,
  percentageChangeClass,
  loadingPercentageClass,
  linkClass,
} from './index.css';

type HeaderProps = {
  tokenSymbol: string;
  tokenName: string;
  tokenAddress: string;
  chainId: number;
  tokenLogoUrl?: string;
  type?: 'popular' | 'trending' | 'chart';
};

type Props = {
  loading: boolean;
  tokenAddress?: string;
  chainId?: number;
  usdValue?: number;
  /** Price change of the last 24h in decimal fractions */
  percentageChange?: number;
  tokenSymbol?: string;
  tokenName?: string;
  tokenLogoUrl?: string;
  type?: 'popular' | 'trending' | 'chart';
  tokenColor?: string;
} & PropsWithoutRef<HTMLProps<HTMLDivElement>>;

function LoadingHeader() {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        marginLeft: 2,
        marginTop: 2,
      }}
    >
      <div className={loadingTokenChainOverlay}>
        <AvatarSkeleton height={36} width={36} variant="round" />
        <AvatarSkeleton height={14} width={14} variant="round" className={chainIndicator} />
      </div>
      <div className={loadingTextClass}>
        <TextSkeleton height={16} width={115} lineClassName={titleLoadingClass} />
        <TextSkeleton height={16} width={42} />
      </div>
    </div>
  );
}

export function Header({
  tokenSymbol,
  tokenName,
  tokenAddress,
  chainId,
  tokenLogoUrl,
  type,
}: HeaderProps) {
  return (
    <div className={headerWrapper}>
      <ImageChainOverlayIcon
        chainId={chainId}
        logoUrl={tokenLogoUrl}
        className={tokenChainOverlayOverrideClass}
      />
      <div className={headerTextWrapper}>
        {/* Using aria-label as the tokenName might be truncated */}
        <h3 className={tokenNameClass} aria-label={tokenName}>
          {/* Link inside the card for accessiblity */}
          {/* We make the clickable area of the link grow to the size
          of the card using CSS trickery */}
          <Link
            onClick={() => {
              if (!type) return;
              logEvent({
                name: EVENT_NAME.HOMEPAGE_CLICK_TOKEN_CARD,
                properties: {
                  chainId,
                  tokenAddress,
                  tokenSymbol,
                  tokenCardType: type,
                } as HOMEPAGE_CLICK_CARD_TYPE,
              });
            }}
            className={linkClass}
            href={{
              pathname: routes.TOKEN_PROFILE,
              query: {
                chainId: NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[chainId],
                contractAddresses: [tokenAddress],
              },
            }}
          >
            {tokenName.length > 15 ? truncate(tokenName, 14, true) : tokenName}
          </Link>
        </h3>
        <span className={tokenSymbolClass}>{tokenSymbol}</span>
      </div>
    </div>
  );
}

export const TokenCard = forwardRef<HTMLDivElement, Props>(function TokenCard(props: Props, ref) {
  // used for the animation of the illustrations in the header component
  const { setCurrentColor } = useHeroColor();
  useEffect(() => {
    if (props.loading || !props.type) return;

    const { type, tokenAddress, tokenSymbol, chainId } = props;

    // we only want to run this once after loading has completed

    logEvent({
      name: EVENT_NAME.HOMEPAGE_TOKEN_CARD_LOADED,
      properties: {
        tokenCardType: type,
        chainId,
        tokenAddress,
        tokenSymbol,
      } as HOMEPAGE_TOKEN_CARD_LOADED_TYPE,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loading]);

  if (props.loading) {
    const { loading, ...rest } = props;
    return (
      <GenericCard className={tokenCardWrapperClass.loading} {...rest}>
        <LoadingHeader />
        <div>
          <TextSkeleton height={20} width={90} lineClassName={lineSkeletonSpacer} />
          <TextSkeleton height={16} width={50} lineClassName={loadingPercentageClass} />
        </div>
      </GenericCard>
    );
  }
  let {
    chainId,
    percentageChange,
    tokenAddress,
    usdValue,
    tokenLogoUrl,
    tokenName,
    tokenSymbol,
    className,
    type,
    loading,
    onMouseEnter,
    onMouseLeave,
    tokenColor,
    ...rest
  } = props;

  if (percentageChange === undefined) {
    console.warn(
      'Token Card rendered with percentageChange set to undefined, this is likely a bug',
    );
    percentageChange = 0;
  }

  if (usdValue === undefined) {
    console.warn('Token Card rendered with usdValue set to undefined, this is likely a bug');
    usdValue = 0;
  }

  if (chainId === undefined || tokenAddress === undefined) {
    console.warn(
      'Token Card attempted to render with undefined chainId or tokenAddress but this is not possible',
    );
    trackError(
      new ClientInputError('Token Card: undefined chain or tokenAddress', {
        cause: `chainId: ${chainId}, tokenAddress: ${tokenAddress}`,
      }),
    );
    return null;
  }

  const formattedUsdValue = usdFormatter.format(usdValue, true);
  let formattedPercentageValue = percentageFormatter.format(percentageChange);

  if (percentageChange < 0.0001 && percentageChange > 0) {
    formattedPercentageValue = '< 0.01%';
  } else if (percentageChange > -0.0001 && percentageChange < 0) {
    formattedPercentageValue = '> -0.01%';
  } else if (percentageChange > 1000) {
    // 1000 = 100,000%
    formattedPercentageValue = '> 100000%';
  } else if (percentageChange < -1000) {
    formattedPercentageValue = '< -100000%';
  }

  // in case a mouse event function has been passed, we need ot merge the handlers

  const mergedMouseEnterHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    if (onMouseEnter) onMouseEnter(e);

    if (tokenColor) setCurrentColor(tokenColor);
  };
  const mergedMouseLeaveHandler: MouseEventHandler<HTMLDivElement> = (e) => {
    if (onMouseLeave) onMouseLeave(e);

    setCurrentColor(null);
  };

  return (
    <GenericCard
      className={`${
        type === 'chart' ? tokenCardWrapperClass.loadedNoHover : tokenCardWrapperClass.loaded
      } ${className ? className : ''}`}
      ref={ref}
      onMouseEnter={mergedMouseEnterHandler}
      onMouseLeave={mergedMouseLeaveHandler}
      {...rest}
    >
      <Header
        type={type}
        chainId={chainId}
        tokenAddress={tokenAddress}
        tokenLogoUrl={tokenLogoUrl}
        tokenName={tokenName || 'Unknown Token'}
        tokenSymbol={tokenSymbol || 'UNKNOWN'}
      />
      <div>
        <span className={usdValueClass}>{formattedUsdValue}</span>
        <span className={percentageChangeClass} data-negative={percentageChange < 0}>
          <VisuallyHidden>Change over the last 24 Hours in percent</VisuallyHidden>
          {formattedPercentageValue}
        </span>
      </div>
    </GenericCard>
  );
});
