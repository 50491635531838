import { errorFallbackTextClass, errorFallbackWrapperClass } from './index.css';

type Props =
  | {
      textOverride: string;
      componentName?: undefined;
    }
  | {
      textOverride?: undefined;
      componentName: string;
    };

export function ErrorFallback({ textOverride, componentName }: Props) {
  return (
    <div className={errorFallbackWrapperClass}>
      <span className={errorFallbackTextClass}>
        {textOverride ? textOverride : `Matcha is currently unable to load ${componentName}...`}
      </span>
    </div>
  );
}
