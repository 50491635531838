import Link from 'next/link';

import ByZeroExPill from '@/components/Landing/Utils/ByZeroExPill';

import ExternalLink from '@/ui/Link/ExternalLink';
import Logo from '@/ui/Logo/Logo';
import LogoCup from '@/ui/Logo/LogoCup';

// utils
import { EVENT_NAME, logEvent } from '@/utils/amplitude';
import { routes } from '@/utils/routes';

import {
  byZeroExClass,
  containerClass,
  contentClass,
  disclaimerClass,
  disclaimerLinkClass,
  headerClass,
  linkClass,
  linkTextClass,
  linksClass,
  linksContainerClass,
  logoClass,
  logoContainerClass,
  logoCupClass,
  logoLinkClass,
} from './index.css';

export function Footer() {
  return (
    <footer className={containerClass}>
      <div className={contentClass}>
        <div className={logoContainerClass}>
          <div className={logoClass}>
            <Link href={{ pathname: routes.INDEX }}>
              <div className={logoLinkClass}>
                <LogoCup className={logoCupClass} />
                <Logo height={16} />
              </div>
            </Link>
            <ByZeroExPill className={byZeroExClass} variant="black06" />
          </div>
        </div>
        <div className={linksContainerClass}>
          <h5 className={headerClass}>Learn</h5>
          <ul className={linksClass}>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.SWAP_TOKENS}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_FOOTER_SWAP_TOKENS })}
              >
                <span className={linkTextClass}>Swap tokens</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.LIMIT_ORDERS}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_FOOTER_LIMIT_ORDERS })}
              >
                <span className={linkTextClass}>Limit orders</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.CROSS_CHAIN_SWAPS}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_FOOTER_CROSS_CHAIN })}
              >
                <span className={linkTextClass}>Cross chain</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.BLOG}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_BLOG_PAGE_BOTTOM })}
              >
                <span className={linkTextClass}>Blog</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.ABOUT_MATCHA}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_FOOTER_COMPANY })}
              >
                <span className={linkTextClass}>Company</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.ZEROEX_HOME}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_FOOTER_DEVELOPER_APIS })}
              >
                <span className={linkTextClass}>Developer APIs</span>
              </ExternalLink>
            </li>
          </ul>
        </div>
        <div className={linksContainerClass}>
          <h5 className={headerClass}>Support</h5>
          <ul className={linksClass}>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.HELP}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_PAGE_BOTTOM })}
              >
                <span className={linkTextClass}>Help center</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.REQUEST_FEATURE}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_REQUEST_FEATURE })}
              >
                <span className={linkTextClass}>Request a feature</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.HELP_FAQ}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_FAQ })}
              >
                <span className={linkTextClass}>FAQs</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.HELP_GETTING_STARTED}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_GETTING_STARTED })}
              >
                <span className={linkTextClass}>Getting started</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.BRAND_ASSETS}
                aria-label="Download brand assets"
              >
                <span className={linkTextClass}>Brand assets</span>
              </ExternalLink>
            </li>
          </ul>
        </div>
        <div className={linksContainerClass}>
          <h5 className={headerClass}>Legal</h5>
          <ul className={linksClass}>
            <li>
              <Link
                className={linkClass}
                href={{ pathname: routes.TERMS }}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_TERMS_PAGE_BOTTOM })}
              >
                <span className={linkTextClass}>Terms</span>
              </Link>
            </li>
            <li>
              <Link
                className={linkClass}
                href={{ pathname: routes.PRIVACY }}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_PRIVACY_PAGE_BOTTOM })}
              >
                <span className={linkTextClass}>Privacy</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className={linksContainerClass}>
          <h5 className={headerClass}>Community</h5>
          <ul className={linksClass}>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.TWITTER}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_TWITTER })}
              >
                <span className={linkTextClass}>X.com</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.WARPCAST}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_WARPCAST })}
              >
                <span className={linkTextClass}>Warpcast</span>
              </ExternalLink>
            </li>
            <li>
              <ExternalLink
                className={linkClass}
                href={routes.DISCORD}
                onClick={() => logEvent({ name: EVENT_NAME.CLICK_HELP_DISCORD })}
              >
                <span className={linkTextClass}>Discord</span>
              </ExternalLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={disclaimerClass}>
        *as listed by{' '}
        <ExternalLink className={disclaimerLinkClass} href={routes.DEFINED_FI}>
          defined.fi
        </ExternalLink>
      </div>
    </footer>
  );
}
