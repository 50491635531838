import { containerClass } from './index.css';
import { MarketingFeaturesSection } from './MarketingFeaturesSection';
import { MarketingLiquiditySection } from './MarketingLiquiditySection';

export function MarketingSection() {
  return (
    <div className={containerClass}>
      <MarketingLiquiditySection />
      <MarketingFeaturesSection />
    </div>
  );
}
