import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { nanoid } from 'nanoid';
import { ReactNode, useMemo, useRef, useState } from 'react';

import { containerClass, itemClass } from './index.css';

type Item = {
  /**
   * The label to be displayed
   */
  label: ReactNode;
  /**
   * The underlying value of the item
   */
  value: string;
};

type HorizontalSelectionProps = {
  /**
   * The items to be displayed
   */
  items: Item[];
  /**
   * The function to be called when an item is selected
   */
  onSelect: (value: string) => void;
  /**
   * The selected item
   */
  selected: string;
};

/**
 * A horizontal selection component, this component is currently only available in controlled mode
 */
export function HorizontalSelection({ items, onSelect, selected }: HorizontalSelectionProps) {
  const randomID = useMemo(() => {
    return nanoid();
  }, []);

  const refs = useRef<Record<string, HTMLLabelElement>>({});

  const [keyboardIndex, setKeyboardIndex] = useState<number | null>(null);

  return (
    <fieldset className={containerClass} role="radiogroup">
      <legend>
        <VisuallyHidden>Select a network.</VisuallyHidden>
      </legend>
      <VisuallyHidden id={`${randomID}-instructions`}>
        Use left and right arrow keys to navigate, and enter or space to select.
      </VisuallyHidden>
      {items.map((item, index) => (
        <label
          data-checked={selected === item.value}
          aria-describedby={`${randomID}-instructions`}
          role="radio"
          aria-checked={selected === item.value}
          key={item.value}
          htmlFor={`${randomID}-${item.value}`}
          ref={(el) => {
            if (el) {
              refs.current[item.value] = el;
            }
          }}
          tabIndex={
            keyboardIndex === null
              ? selected === item.value
                ? 0
                : -1
              : keyboardIndex === index
                ? 0
                : -1
          }
          onFocus={() => {
            if (keyboardIndex === null) {
              setKeyboardIndex(index);
            }
          }}
          onBlur={() => {
            if (keyboardIndex === index) {
              setKeyboardIndex(null);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'ArrowRight') {
              const nextIndex = index === items.length - 1 ? 0 : index + 1;
              setKeyboardIndex(nextIndex);
              refs.current[items[nextIndex].value].focus();
            } else if (e.key === 'ArrowLeft') {
              const prevIndex = index === 0 ? items.length - 1 : index - 1;
              setKeyboardIndex(prevIndex);
              refs.current[items[prevIndex].value].focus();
            } else if (e.key === 'Enter' || e.key === ' ') {
              onSelect(item.value);
            }
          }}
          onClick={() => {
            onSelect(item.value);
          }}
        >
          <input
            id={`${randomID}-${item.value}`}
            type="radio"
            className={itemClass}
            value={item.value}
            checked={selected === item.value}
            name={randomID}
          />
          {item.label}
        </label>
      ))}
    </fieldset>
  );
}
