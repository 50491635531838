import Image from 'next/image';
import { forwardRef } from 'react';

import * as none from '../../../../public/images/chains/none.svg';
import { tokenBackdropClass, tokenClass, wrapperClass } from './index.css';

type TokenPairIconProps = {
  sellTokenIcon?: string;
  sellTokenSymbol?: string;
  buyTokenIcon?: string;
  buyTokenSymbol?: string;
  className?: string;
  width?: number;
  height?: number;
};

export const TokenPairIcon = forwardRef<HTMLDivElement, TokenPairIconProps>(function TokenChainIcon(
  {
    className,
    sellTokenSymbol,
    buyTokenSymbol,
    sellTokenIcon,
    buyTokenIcon,
    width = 36,
    height = 36,
  },
  ref,
) {
  return (
    <div ref={ref} className={`${wrapperClass} ${className === undefined ? '' : className}`}>
      <div className={tokenBackdropClass}>
        <Image
          className={tokenClass}
          src={sellTokenIcon || none.default.src}
          alt={sellTokenSymbol || 'Sell token'}
          height={height}
          width={width}
          // style={{
          //   maskImage: `url(#${randomId.current})`,
          // }}
        />
        {/*<svg className={svgClass} height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
          <mask id={randomId.current}>
            <circle r="50%" cy="50%" cx="50%" fill="white" />
            <circle r="50%" cx="100%" cy="25%" className={circleClass} fill="black" />
          </mask>
        </svg>*/}
      </div>
      <div className={tokenBackdropClass}>
        <Image
          className={tokenClass}
          src={buyTokenIcon || none.default.src}
          alt={buyTokenSymbol || 'Buy token'}
          height={height}
          width={width}
        />
      </div>
    </div>
  );
});
