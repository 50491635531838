import { animated, useSprings } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import React, {
  Children,
  createContext,
  forwardRef,
  HTMLAttributes,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { classNames } from '../../../utils/classnames';
import {
  indicatorClass,
  indicatorContainerClass,
  indicatorPlaceholderClass,
  pagesContainerClass,
  rootClass,
} from './index.css';

type PagesProps = {
  children: React.ReactNode;
  className?: string;
};

type SwipePaginationContextType = {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPages: number | null;
  setTotalPages: (pages: number) => void;
};

const SwipePaginationContext = createContext<SwipePaginationContextType>({
  currentPage: 0,
  setCurrentPage: () => {},
  totalPages: null,
  setTotalPages: () => {},
});

function Root({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  return (
    <SwipePaginationContext.Provider
      value={{ currentPage, setCurrentPage, totalPages, setTotalPages }}
    >
      <div ref={containerRef} className={classNames(rootClass, className)} {...props}>
        {children}
      </div>
    </SwipePaginationContext.Provider>
  );
}

function Pages({ children, className }: PagesProps) {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  const { setTotalPages, totalPages, currentPage, setCurrentPage } =
    useContext(SwipePaginationContext);

  const [isSwiping, setIsSwiping] = useState(false);

  const [touchStartPage, setTouchStartPage] = useState<null | number>(null);

  const pageRefs = useRef<HTMLDivElement[]>([]);

  const [maxPageHeight, setMaxPageHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  const [isSetup, setIsSetup] = useState(false);

  useLayoutEffect(() => {
    // Reset height calculation when pages change
    const updateDimensions = () => {
      if (!pageRefs.current.length) return;

      // Calculate max height from all page refs
      const heights = pageRefs.current.filter(Boolean).map((el) => el.scrollHeight);

      const newMaxHeight = Math.max(...heights);
      setMaxPageHeight(newMaxHeight);
    };

    // Initial calculation
    updateDimensions();
    const observer = new ResizeObserver(updateDimensions);
    pageRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, [children, containerWidth]); // Re-run when children or width changes

  useLayoutEffect(() => {
    const updateContainerWidth = () => {
      setContainerWidth(containerRef?.offsetWidth ?? 0);
    };
    updateContainerWidth();
    const observer = new ResizeObserver(updateContainerWidth);
    observer.observe(window.document.body);
    return () => observer.disconnect();
  }, [containerRef]);

  const [springs, api] = useSprings(
    totalPages || 0,
    (index) => ({
      x: index * containerWidth,
      display: 'block',
      // on the first render, we don't need to animate the springs
      immediate: !isSetup,
      config: {
        tension: 280,
        friction: 32,
      },
    }),
    [totalPages, containerWidth, isSetup],
  );

  useDrag(
    ({ active, movement: [mx], direction: [xDir], cancel }) => {
      if (totalPages === null || !containerWidth) return;
      if (active && !isSwiping) {
        setIsSwiping(true);
        setTouchStartPage(currentPage);
      }
      if (!active && isSwiping) {
        setIsSwiping(false);
        setTouchStartPage(null);
        return;
      }
      const page = touchStartPage ?? currentPage;

      if (active && Math.abs(mx) > containerWidth / 4) {
        const newPage = page + (xDir > 0 ? -1 : 1);
        if (newPage >= 0 && newPage < totalPages) {
          setCurrentPage(newPage);
        }
      } else if (active && touchStartPage !== null) {
        setCurrentPage(touchStartPage);
      }

      api.start((i) => {
        if (i < page - 1 || i > page + 1) return {};

        // limit the swipe distance to 1 page
        const clampedSwipeDistance = Math.max(-containerWidth, Math.min(mx, containerWidth));

        const x = (i - page) * containerWidth + (active ? clampedSwipeDistance : 0);

        return {
          x,
          display: 'block',
        };
      });
    },
    {
      axis: 'lock',
      filterTaps: true,
      bounds: { left: -containerWidth, right: containerWidth },
      rubberband: true,
      preventScroll: false,
      target: containerRef ?? undefined,
    },
  );

  useEffect(() => {
    setTotalPages(React.Children.count(children));
  }, [children, setTotalPages]);

  // // Update springs when container width changes
  useLayoutEffect(() => {
    if (containerWidth && !isSwiping) {
      api.start((i) => ({
        x: (i - currentPage) * containerWidth,
      }));
    }
  }, [api, containerWidth, currentPage, isSwiping]);

  // Don't render content until dimensions are ready
  if (containerWidth === 0) {
    return (
      <div
        ref={(ref) => {
          // on the first render, set the container width
          // we don't need to keep track of this ref
          if (ref) {
            setContainerWidth(ref.offsetWidth);
          }
        }}
        className={classNames(pagesContainerClass, className)}
      />
    );
  }

  return (
    <div
      ref={(node) => {
        if (node) {
          setContainerRef(node);
          setIsSetup(true);
        }
      }}
      className={classNames(pagesContainerClass, className)}
      style={{ height: maxPageHeight }}
    >
      {Children.toArray(children).map((child, i) => {
        if (i >= springs.length) return null;
        const { display, x } = springs[i];
        return (
          <animated.div
            key={i}
            ref={(el) => {
              if (el) {
                pageRefs.current[i] = el;
              }
            }}
            style={{
              display,
              x,
              position: 'absolute',
              width: containerWidth,
              height: 'fit-content',
              minHeight: maxPageHeight,
              willChange: 'transform',
              touchAction: 'pan-y',
            }}
          >
            {child}
          </animated.div>
        );
      })}
    </div>
  );
}

const Page = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(function Page(
  { children, ...props },
  ref,
) {
  return (
    <div ref={ref} {...props} onMouseDown={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
});

function PageIndicator({ className }: { className?: string }) {
  const { currentPage, totalPages, setCurrentPage } = useContext(SwipePaginationContext);
  if (totalPages === null) {
    return (
      <div className={classNames(indicatorContainerClass, className)}>
        <div className={indicatorPlaceholderClass} />
      </div>
    );
  }

  return (
    <div className={classNames(indicatorContainerClass, className)}>
      {Array.from({ length: totalPages }).map((_, index) => (
        <div
          className={indicatorClass}
          key={index}
          data-active={index === currentPage ? 'true' : 'false'}
          onClick={() => setCurrentPage(index)}
        />
      ))}
    </div>
  );
}

const MobileSwipePagination = {
  Root,
  Page,
  Pages,
  PageIndicator,
};

export default MobileSwipePagination;
