import { VisuallyHidden } from '@radix-ui/react-visually-hidden';

import { percentageFormatter } from '@/lib/formatting/percentageFormatter';

import {
  headerWrapper,
  tokenNameClass,
  tokenSymbolClass,
  headerTextWrapper,
} from '@/components/NewLanding/TokenCard/index.css';

import { Token } from '@/data/tokens';

import { ImageChainOverlayIcon } from '@/ui/Icons/TokenChainIcon';

import { truncate } from '@/utils/string';

import { usdFormatter } from '../../../../lib/formatting';
// utils
import type { TokenPrice } from '../../utils';
import { getDate } from '../../utils';
import {
  tooltipPrice,
  tooltipDate,
  tooltipContainer,
  tooltipPriceContainer,
  timeFrameMessage,
} from './index.css';

interface HeaderProps {
  /** Popular Token information for Icon name & symbol */
  mostPopularTokenInfo: Token;
  /** passed from LineChartWithTooltip contains data for hovered data point */
  tooltipData: TokenPrice | undefined;
  /** Percentage change from chart data */
  percentChangeFormatted?: number;
}

/**
 * LineChartWIthTooltip which is of variant type 'home-page' renders this
 * `HeaderCard` component to display TokenMetaData and tooltip price/date data
 * on hover.
 */
const ChartHeaderCard = ({
  mostPopularTokenInfo,
  tooltipData,
  percentChangeFormatted,
}: HeaderProps) => {
  const percentToRender =
    mostPopularTokenInfo &&
    mostPopularTokenInfo.percentageChange24 !== undefined &&
    percentChangeFormatted === undefined
      ? percentageFormatter.format(mostPopularTokenInfo.percentageChange24)
      : percentChangeFormatted
        ? `${percentChangeFormatted.toPrecision(2)}% `
        : '';

  return (
    <div className={tooltipContainer}>
      <div className={headerWrapper}>
        <ImageChainOverlayIcon
          {...mostPopularTokenInfo}
          logoUrl={mostPopularTokenInfo.logo ?? ''}
        />

        <div className={headerTextWrapper}>
          <h3 className={tokenNameClass} aria-label={mostPopularTokenInfo.name}>
            {mostPopularTokenInfo.name && mostPopularTokenInfo.name.length > 15
              ? truncate(mostPopularTokenInfo.name, 14, true)
              : mostPopularTokenInfo.name}
          </h3>
          <span className={tokenSymbolClass}>{mostPopularTokenInfo.symbol}</span>
        </div>
      </div>
      <div className={tooltipPriceContainer}>
        {tooltipData ? (
          <>
            <span className={tooltipPrice}>{usdFormatter.format(tooltipData.closing, true)}</span>
            <span className={tooltipDate}>
              {`${getDate(tooltipData).toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })}`}
            </span>
          </>
        ) : (
          <>
            {mostPopularTokenInfo.priceUSD && (
              <span className={tooltipPrice}>
                {usdFormatter.format(mostPopularTokenInfo.priceUSD, true)}
              </span>
            )}
            <div
              className={tooltipDate}
              data-positive={percentChangeFormatted && percentChangeFormatted > 0}
            >
              <VisuallyHidden>Change over the last 24 Hours in percent</VisuallyHidden>
              {percentToRender}&nbsp;
              <span className={timeFrameMessage}>Last 24 hours</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChartHeaderCard;
