import Link from 'next/link';
import { PropsWithoutRef } from 'react';
import { HTMLProps } from 'react';

import { NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID } from '../../../constants/chain';
import { percentageFormatter, usdFormatter } from '../../../lib/formatting';
import { ImageChainOverlayIcon } from '../../../ui/Icons/TokenChainIcon';
import { logEvent } from '../../../utils/amplitude';
import { EVENT_NAME, HOMEPAGE_CLICK_CARD_TYPE } from '../../../utils/amplitude';
import { classNames } from '../../../utils/classnames';
import { routes } from '../../../utils/routes';
import {
  identifierClass,
  linkClass,
  percentageChangeClass,
  stackedInfoWrapperClass,
  tokenNameClass,
  tokenSymbolClass,
  usdValueClass,
  wrapperClass,
} from './index.css';

type Props = {
  loading: boolean;
  tokenAddress?: string;
  chainId?: number;
  usdValue?: number;
  /** Price change of the last 24h in decimal fractions */
  percentageChange?: number;
  tokenSymbol?: string;
  tokenName?: string;
  tokenLogoUrl?: string;
  type?: 'popular' | 'trending';
} & PropsWithoutRef<HTMLProps<HTMLDivElement>>;

export function MobileTokenCard({
  loading,
  tokenSymbol = 'UNKNOWN',
  tokenName = 'Unknown Token',
  tokenLogoUrl,
  chainId,
  percentageChange = 0,
  usdValue = 0,
  tokenAddress,
  type,
  className,
  ...props
}: Props) {
  if (!chainId || !tokenAddress) return null;

  const formattedUsdValue = usdFormatter.format(usdValue, true);
  let formattedPercentageValue = percentageFormatter.format(percentageChange);

  if (percentageChange < 0.0001 && percentageChange > 0) {
    formattedPercentageValue = '< 0.01%';
  } else if (percentageChange > -0.0001 && percentageChange < 0) {
    formattedPercentageValue = '> -0.01%';
  } else if (percentageChange > 1000) {
    // 1000 = 100,000%
    formattedPercentageValue = '> 100000%';
  } else if (percentageChange < -1000) {
    formattedPercentageValue = '< -100000%';
  }

  return (
    <div className={classNames(wrapperClass, className)} {...props}>
      <div className={identifierClass}>
        <ImageChainOverlayIcon chainId={chainId} logoUrl={tokenLogoUrl} />
        <div className={stackedInfoWrapperClass}>
          <div>
            <h3 aria-label={tokenName} className={tokenNameClass}>
              <Link
                className={linkClass}
                onClick={() => {
                  if (!type) return;
                  logEvent({
                    name: EVENT_NAME.HOMEPAGE_CLICK_TOKEN_CARD,
                    properties: {
                      chainId,
                      tokenAddress,
                      tokenSymbol,
                      tokenCardType: type,
                    } as HOMEPAGE_CLICK_CARD_TYPE,
                  });
                }}
                href={{
                  pathname: routes.TOKEN_PROFILE,
                  query: {
                    chainId: NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[chainId],
                    contractAddresses: [tokenAddress],
                  },
                }}
              >
                {tokenName}
              </Link>
            </h3>
            <span className={tokenSymbolClass}>{tokenSymbol}</span>
          </div>
        </div>
      </div>
      <div className={stackedInfoWrapperClass}>
        <span className={usdValueClass}>{formattedUsdValue}</span>
        <span
          className={percentageChangeClass}
          data-negative={percentageChange < 0}
          data-positive={percentageChange > 0}
        >
          {formattedPercentageValue}
        </span>
      </div>
    </div>
  );
}
