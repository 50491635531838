const ONE_MINUTE = 60 * 1000;
const THIRTY_SECONDS = 30 * 1000;
/**
 * This function should be used in combination with date-fns#formatDistance.
 * The returned value of this function is the amount of time (in milliseconds)
 * until the next refresh of a label containing a formated distance should be scheduled
 *
 * @param currentDate - The current date
 * @param startDate - The date to compare with
 *
 * @returns The time in milliseconds until the next label refresh should be scheduled
 */
export function getNextUpdateCycle(currentDate: Date, startDate: Date): number {
  // formatDistance groups the distance between two dates in multiple groups
  // We should calculate the distance between the current date and the border of the next
  // higher group (see all groups here: https://date-fns.org/v3.3.1/docs/formatDistance)

  const distance = currentDate.getTime() - startDate.getTime();

  if (distance < 45 * ONE_MINUTE) {
    // until we reach 45 minutes, we want to run at the next 30 seconds mark

    let distanceToNextMinute = distance % ONE_MINUTE;

    if (distanceToNextMinute < THIRTY_SECONDS) {
      return THIRTY_SECONDS - distanceToNextMinute;
    } else {
      return ONE_MINUTE - distanceToNextMinute;
    }
  }

  // for simplicity, we will simply target the next 30 minutes or full hour mark, as this will
  // work for everything up to one day and is long enough to not block the event queue
  const distanceToNextHour = distance % (60 * ONE_MINUTE);
  if (distanceToNextHour < 30 * ONE_MINUTE) {
    return 30 * ONE_MINUTE - distanceToNextHour;
  }
  return 60 * ONE_MINUTE - distanceToNextHour;
}
