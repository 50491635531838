import { animated, useTransition } from '@react-spring/web';
import { produce } from 'immer';
import { CSSProperties, useEffect, useReducer, useRef, useState } from 'react';
import { useMedia } from 'react-use';
import { Address } from 'viem';

import ENV from '@/environment';

import { breakpoints } from '@/styles/util';

import { EVENT_NAME } from '@/utils/amplitude';
import { logTxnModuleEvent } from '@/utils/amplitude';

import { ErrorFallback } from '../ErrorFallback';
import { RecentTradeCard } from '../RecentTradeCard';
import {
  animationContainer,
  cardClass,
  containerClass,
  headerTextClass,
  listClass,
  wrapperClass,
} from './index.css';

type Event = {
  sellAmount: string;
  sellSymbol: string;
  sellTokenAddress: Address;
  buyAmount: string;
  buySymbol: string;
  buyTokenAddress: Address;
  chainId: number;
  buyTokenLogo?: string;
  sellTokenLogo?: string;
  __timestamp: number;
};

const eventSourceUrl = `${ENV.TRADE_BROADCASTER_URL}/recent-trades`;

type ActionType =
  | { type: 'set_max_items'; payload: number }
  | { type: 'add_trade'; payload: Event };
type EventState = {
  eventBuffer: Event[];
  events: Event[];
  maxItems: number;
};

const largeNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
  notation: 'compact',
});
const smallNumberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 3,
  notation: 'compact',
});

const reducer = (state: EventState, action: ActionType) =>
  produce(state, (draft) => {
    if (action.type === 'add_trade') {
      logTxnModuleEvent(
        EVENT_NAME.HOMEPAGE_RECENT_CARD_LOADED,
        {
          symbol: action.payload.sellSymbol,
          address: action.payload.sellTokenAddress,
          chainId: action.payload.chainId,
        },
        {
          symbol: action.payload.buySymbol,
          address: action.payload.buyTokenAddress,
          chainId: action.payload.chainId,
        },
      );
      draft.eventBuffer.unshift(action.payload);
      draft.events.unshift(action.payload);
      while (draft.events.length > draft.maxItems) {
        draft.events.pop();
      }
      while (draft.eventBuffer.length > 5) {
        draft.eventBuffer.pop();
      }
    } else {
      draft.maxItems = action.payload;
      draft.events = [...draft.eventBuffer];
      while (draft.events.length > draft.maxItems) {
        draft.events.pop();
      }
    }
  });

export function RecentTrades() {
  const [hasError, setHasError] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const isTablet = useMedia(breakpoints.tablet, false);
  const isDesktop = useMedia(breakpoints.desktop, true);
  const [state, dispatch] = useReducer(reducer, { maxItems: 5, events: [], eventBuffer: [] });

  useEffect(() => {
    const maxItems = isTablet && !isDesktop ? 3 : 5;
    dispatch({ type: 'set_max_items', payload: maxItems });
  }, [isTablet, isDesktop]);

  useEffect(() => {
    const eventSource = new EventSource(eventSourceUrl);
    eventSource.onmessage = (ev) => {
      try {
        const event = JSON.parse(ev.data) as Event;
        // removing commas from the number strings as they can cause parsing errors
        const buyAmount = Number(event.buyAmount.replaceAll(/,/g, ''));
        const sellAmount = Number(event.sellAmount.replaceAll(/,/g, ''));

        event.buyAmount =
          buyAmount >= 1
            ? largeNumberFormatter.format(buyAmount)
            : smallNumberFormatter.format(buyAmount);
        event.sellAmount =
          sellAmount >= 1
            ? largeNumberFormatter.format(sellAmount)
            : smallNumberFormatter.format(sellAmount);

        // if the number is 0, we format to <0.001
        if (event.sellAmount === '0') {
          event.sellAmount = '<0.001';
        }
        if (event.buyAmount === '0') {
          event.buyAmount = '<0.001';
        }

        // if either of the amounts are NaN, we skip the trade
        if (event.sellAmount === 'NaN' || event.buyAmount === 'NaN') {
          return;
        }
        dispatch({
          type: 'add_trade',
          payload: event,
        });
      } catch (e) {
        // we ignore the trade
      }
    };

    eventSource.onopen = () => {
      setHasError(false);
    };

    eventSource.onerror = () => {
      // EventSource will automatically retry
      setHasError(true);
    };

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    if (!wrapperRef.current) return;
    // total padding applied to both sides of the wrapper
    const totalPadding = 32;
    const current = wrapperRef.current;
    const width = current.clientWidth;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        let size;
        if (entry.contentBoxSize && entry.contentBoxSize.length > 0) {
          size = entry.contentBoxSize[0].inlineSize;
        } else {
          // this is only for backwards-compatibility
          size = entry.contentRect.width;
        }
        setWrapperWidth(size - totalPadding);
      }
    });

    resizeObserver.observe(current);
    setWrapperWidth(width - totalPadding);

    return () => {
      resizeObserver.unobserve(current);
    };
  }, [wrapperRef]);

  const cssProps = {
    '--num-cards': isDesktop ? 5 : isTablet ? 3 : 1.5,
    '--scroller-width': wrapperWidth + 'px',
  } as CSSProperties;

  const transitions = useTransition(state.events, {
    // the width animation is needed to animate the rest of the list being pushed to the side
    from: { 'opacity': 0, '--scale': 0, 'transform': 'translateX(-100px)' },
    keys: (item) => `${item.buyTokenAddress}-${item.sellTokenAddress}-${item.__timestamp}`,
    enter: { 'opacity': 1, '--scale': 1, 'transform': 'translateX(0)' },
    leave: { 'opacity': 0, '--scale': 0, 'transform': 'translateX(100)' },
  });
  return (
    <div className={containerClass}>
      <h3 className={headerTextClass}>Recent trades on Matcha</h3>
      {hasError && state.events.length === 0 ? (
        <ErrorFallback componentName="Recent Trades" />
      ) : (
        <div style={cssProps} className={wrapperClass} ref={wrapperRef}>
          <ul className={listClass}>
            {transitions((style, event) => (
              <animated.div className={animationContainer} style={style}>
                <RecentTradeCard
                  className={cardClass}
                  buyTokenAddress={event.buyTokenAddress}
                  buyTokenSymbol={event.buySymbol}
                  buyTokenAmount={event.buyAmount}
                  buyTokenLogo={event.buyTokenLogo}
                  sellTokenAddress={event.sellTokenAddress}
                  sellTokenSymbol={event.sellSymbol}
                  sellTokenAmount={event.sellAmount}
                  sellTokenLogo={event.sellTokenLogo}
                  chainId={event.chainId}
                  timestamp={new Date(event.__timestamp)}
                  key={`${event.sellTokenAddress}-${event.buyTokenAddress}-${event.__timestamp}`}
                />
              </animated.div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
