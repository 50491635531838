import { chunk } from 'lodash';
import Image from 'next/image';
import { ReactNode, useMemo, useState } from 'react';
import { useMedia } from 'react-use';

import * as PaginatedHorizontalScroll from '@/components/NewLanding/PaginatedHorizontalScroll';

import { NETWORK_NAME_PER_CHAIN_ID, SUPPORTED_CHAIN_IDS } from '@/constants/chain';

import { breakpoints } from '@/styles/util';

import { ChainIcons } from '@/ui/Icons/TokenChainIcon';
import { Select } from '@/ui/Select';

import {
  HOMEPAGE_CLICK_PAGINATION_PROPS,
  HOMEPAGE_NETWORK_SELECT_PROPS,
  logEvent,
} from '@/utils/amplitude';

import { ChevronDownIcon } from '../../../ui/Icons';
import { classNames } from '../../../utils/classnames';
import { ErrorFallback } from '../ErrorFallback';
import MobileSwipePagination from '../MobileSwipePagination';
import { MobileTokenCard } from '../MobileTokenCard';
import { TokenCard } from '../TokenCard';
import { TokensByChainName } from '../types';
import {
  filterAndPaginationClass,
  headerClass,
  headerTextClass,
  containerClass,
  scrollerClass,
  tradeCardClass,
  labelClass,
  selectIconClass,
  visibleOnTablet,
  mobileSwipePaginationRootClass,
  mobileTokenCardClass,
  iconNextClass,
  iconPreviousClass,
  paginationButtonsClass,
  buttonClass,
} from './index.css';

const ORDERED_FILTER_LIST: (string | number)[] = ['All networks', ...SUPPORTED_CHAIN_IDS];

const maxPage = 2;
const minPage = 1;

export function MostPopularTokens({ tokenResults }: { tokenResults: TokensByChainName }) {
  const [selectedFilter, setSelectedFilter] = useState('All networks');

  const isDesktop = useMedia(breakpoints.desktop, true);
  const isTablet = useMedia(breakpoints.tablet, false);
  const isMobile = useMedia(breakpoints.mobile, false);

  const [currentPage, setCurrentPage] = useState(1);

  const filters = useMemo(() => {
    return ORDERED_FILTER_LIST.map((id) => {
      const filterName =
        id !== 'All networks' ? NETWORK_NAME_PER_CHAIN_ID[id as number] : 'All networks';

      const icon =
        id !== 'All networks' ? (
          <Image
            src={ChainIcons[id as number]}
            className={selectIconClass}
            alt={`${filterName} logo`}
            height={16}
            width={16}
          />
        ) : null;

      return {
        label: (
          <div className={labelClass}>
            {icon}
            <span>{filterName}</span>
          </div>
        ),
        value: filterName,
      };
    });
  }, []);

  const [result, items] = useMemo<
    ['success', { id: string; node: ReactNode }[]] | ['error', null]
  >(() => {
    const tokens = tokenResults[selectedFilter];
    if (!tokens || tokens.type === 'error') {
      return ['error', null];
    }
    const mappedItems = tokens.data.map((item) => ({
      id: item.address,
      node: (
        <TokenCard
          type="popular"
          chainId={item.chainId}
          percentageChange={item.percentageChange24}
          tokenAddress={item.address}
          tokenName={item.name}
          tokenSymbol={item.symbol}
          usdValue={item.priceUSD}
          loading={false}
          tokenLogoUrl={item.logo || undefined}
          tokenColor={item.color}
          className={tradeCardClass}
        />
      ),
    }));

    return ['success', mappedItems];
  }, [selectedFilter, tokenResults]);

  const onNetworkSelect = (newFilter: string) => {
    logEvent({
      name: 'homepage_network_select',
      properties: {
        filter: newFilter,
        tokenCardType: 'popular',
      } as HOMEPAGE_NETWORK_SELECT_PROPS,
    });
    setSelectedFilter(newFilter);
  };

  const onPagination = (newPage: number) => {
    logEvent({
      name: 'homepage_click_pagination',
      properties: {
        filter: selectedFilter,
        tokenCardType: 'popular',
        paginationDirection: currentPage > newPage ? 'left' : 'right',
      } as HOMEPAGE_CLICK_PAGINATION_PROPS,
    });
    setCurrentPage(newPage);
  };

  const results = tokenResults[selectedFilter];

  return (
    <div className={containerClass}>
      <div className={headerClass}>
        <h1 className={headerTextClass}>Most popular tokens</h1>
        <div className={filterAndPaginationClass}>
          <Select
            placeholderText="Select a network"
            selected={selectedFilter}
            onSelect={onNetworkSelect}
            items={filters}
            variant="secondary"
          />
          <div className={paginationButtonsClass}>
            <button
              aria-label="previous page"
              className={buttonClass}
              disabled={result === 'error' || currentPage === minPage}
              onClick={() => onPagination(currentPage - 1)}
            >
              <ChevronDownIcon className={iconPreviousClass} />
            </button>
            <button
              aria-label="next page"
              className={buttonClass}
              disabled={result === 'error' || currentPage === maxPage}
              onClick={() => onPagination(currentPage + 1)}
            >
              <ChevronDownIcon className={iconNextClass} />
            </button>
          </div>
        </div>
      </div>
      {result === 'error' || results.type === 'error' ? (
        <ErrorFallback componentName="the most popular tokens" />
      ) : (
        <>
          <PaginatedHorizontalScroll.Root
            columns={isDesktop ? 5 : isTablet ? 3 : 3}
            isHorizontalScroll={isMobile}
            page={currentPage}
            className={classNames(scrollerClass, visibleOnTablet)}
          >
            <PaginatedHorizontalScroll.List items={items} />
          </PaginatedHorizontalScroll.Root>
          <MobileSwipePagination.Root className={mobileSwipePaginationRootClass}>
            <MobileSwipePagination.Pages>
              {results.type === 'success' &&
                chunk(results.data, 5).map((currentPage) => (
                  <MobileSwipePagination.Page key={`page-${currentPage[0].address}`}>
                    {currentPage.map((item) => (
                      <div key={item.address} style={{ marginBottom: '10px' }}>
                        <MobileTokenCard
                          loading={false}
                          tokenAddress={item.address}
                          chainId={item.chainId}
                          tokenName={item.name ?? ''}
                          tokenSymbol={item.symbol ?? ''}
                          tokenLogoUrl={item.logo}
                          percentageChange={item.percentageChange24 ?? 0}
                          usdValue={item.priceUSD ?? 0}
                          className={mobileTokenCardClass}
                        />
                      </div>
                    ))}
                  </MobileSwipePagination.Page>
                ))}
            </MobileSwipePagination.Pages>
            <MobileSwipePagination.PageIndicator />
          </MobileSwipePagination.Root>
        </>
      )}
    </div>
  );
}
