import { useMemo } from 'react';
import { HTMLProps, PropsWithoutRef } from 'react';

import { axisClass, tickLabels } from '../index.css';
import type { TokenPrice } from '../utils';
import { scaledAxisTickLabels } from '../utils';

type AxisProps = {
  range: number;
  tokenData: TokenPrice[] | undefined;
} & PropsWithoutRef<HTMLProps<HTMLDivElement>>;

export const Axis = ({ range, tokenData, className, ...props }: AxisProps) => {
  /**
   * Tick Values are calculated and used for X-Axis
   * If there is no tokenData then create a date array ,to be used with the error chart, of
   * 9 Date Objects, each 1 hour apart and starting from the current time.
   */
  const tickValues = useMemo(() => {
    if (tokenData) {
      const datesOnly: Date[] = [];
      tokenData.forEach((d) => datesOnly.push(new Date(d.date)));
      return scaledAxisTickLabels({ tokenDates: datesOnly, range: range });
    } else {
      const datesOnly: Date[] = [];
      const now = new Date(Date.now());
      for (let i = 0; i < 9; i++) {
        const tempDate = new Date();
        tempDate.setHours(now?.getHours() - i);
        datesOnly.push(tempDate);
      }
      return scaledAxisTickLabels({ tokenDates: datesOnly, range: 0 });
    }
  }, [range, tokenData]);

  const listItems = tickValues.map((d, index) => (
    <span key={`${d.date}-${index}`} className={tickLabels}>
      {d.date}
    </span>
  ));

  return <div className={`${className ?? axisClass}`}>{listItems}</div>;
};
