import { formatDistance } from 'date-fns';
import Link from 'next/link';
import { CSSProperties, useEffect, useState } from 'react';
import { Address } from 'viem';

import { NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID } from '@/constants/chain';

import { TokenPairIcon } from '@/ui/Icons/TokenPairIcon';

import { EVENT_NAME, logTxnModuleEvent } from '@/utils/amplitude';
import { getNextUpdateCycle } from '@/utils/date';
import { routes } from '@/utils/routes';
import { truncate } from '@/utils/string';

import { GenericCard } from '../GenericCard';
import { cardClass, linkClass, textWrapperClass, timeSinceClass, toClass } from './index.css';

type Props = {
  sellTokenSymbol: string;
  buyTokenSymbol: string;
  sellTokenAmount: string;
  /** Used for setting up the trade via query parameters */
  buyTokenAmount: string;
  buyTokenLogo?: string;
  sellTokenLogo?: string;
  timestamp: Date;
  chainId: number;
  sellTokenAddress: Address;
  buyTokenAddress: Address;
  style?: CSSProperties;
  className?: string;
};

function getDistance(currentTime: Date, publishedTime: Date) {
  const lessThanAMinute = currentTime.getTime() - publishedTime.getTime() < 60 * 1000;
  if (lessThanAMinute) {
    return 'a few seconds';
  } else {
    return formatDistance(publishedTime, currentTime);
  }
}

export function RecentTradeCard({
  chainId,
  timestamp,
  sellTokenSymbol,
  sellTokenAddress,
  sellTokenLogo,
  buyTokenSymbol,
  buyTokenLogo,
  sellTokenAmount,
  buyTokenAmount,
  buyTokenAddress,
  style,
  className,
}: Props) {
  const [relativeDistance, setRelativeDistance] = useState(getDistance(new Date(), timestamp));
  useEffect(() => {
    let timeout: NodeJS.Timer;
    // we will always reassign the handler in the closure to the current cycle
    // this will cause us to always being able to correctly clean up on unmount
    let handler = () => {
      const now = new Date();
      setRelativeDistance(getDistance(now, timestamp));
      timeout = setTimeout(handler, getNextUpdateCycle(now, timestamp));
    };

    timeout = setTimeout(handler, getNextUpdateCycle(new Date(), timestamp));

    return () => {
      clearTimeout(timeout);
    };
  }, [timestamp]);
  return (
    <GenericCard style={style} className={`${cardClass} ${className ? className : ''}`}>
      <TokenPairIcon
        buyTokenIcon={buyTokenLogo}
        sellTokenIcon={sellTokenLogo}
        sellTokenSymbol={sellTokenSymbol}
        buyTokenSymbol={buyTokenSymbol}
      />

      <div className={textWrapperClass}>
        {/* Link inside the card for accessiblity */}
        {/* We make the clickable area of the link grow to the size
          of the card using CSS trickery */}
        <Link
          onClick={() => {
            logTxnModuleEvent(
              EVENT_NAME.HOMEPAGE_CLICK_RECENT_TRADE,
              {
                symbol: sellTokenSymbol,
                address: sellTokenAddress,
                chainId,
              },
              {
                symbol: buyTokenSymbol,
                address: buyTokenAddress,
                chainId,
              },
            );
          }}
          className={linkClass}
          href={{
            pathname: routes.TOKEN_PROFILE,
            query: {
              sellAddress: sellTokenAddress,
              sellChain: chainId.toString(),
              chainId: NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[chainId],
              contractAddresses: [buyTokenAddress],
            },
          }}
        >
          {sellTokenAmount} {truncate(sellTokenSymbol, 5, true)}
        </Link>
        <br />
        <span className={toClass}>to </span>
        <span>
          {buyTokenAmount} {truncate(buyTokenSymbol, 5, true)}
        </span>
      </div>
      <span className={timeSinceClass}>{relativeDistance} ago</span>
    </GenericCard>
  );
}
