import Link from 'next/link';
import { forwardRef, useMemo } from 'react';

import { useIsAnnouncementBannerVisible } from '@/components/Header/AnnouncementBanner';

import {
  NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID,
  SUPPORTED_CHAINS_BY_CHAIN_ID,
} from '@/constants/chain';

import { EVENT_NAME, logEvent } from '@/utils/amplitude';
import { routes } from '@/utils/routes';

import { useMatchaWallets } from '../../../hooks/useMatchaWallets';
import { getConnectedChain } from '../../../utils/wallet';
import { GreenArchImage, YellowCircleImage } from '../Images';
import {
  buttonClass,
  containerClass,
  extraTopPaddingContainerClass,
  greenArchClass,
  headerContainerClass,
  headerTextAltClass,
  headerTextClass,
  yellowCircleClass,
} from './index.css';

export const Hero = forwardRef<HTMLDivElement, {}>(function Hero(_, ref) {
  const { ethereumWallet } = useMatchaWallets();
  const chain = getConnectedChain(ethereumWallet);
  const { isAnnouncementBannerVisible } = useIsAnnouncementBannerVisible();

  const query = useMemo(
    () => ({
      chainId: (chain && NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[chain.id]) ?? 'ethereum',
      contractAddresses: [
        (chain && SUPPORTED_CHAINS_BY_CHAIN_ID[chain.id]?.nativeCurrency.symbol.toLowerCase()) ??
          'eth',
      ],
    }),
    [chain],
  );

  return (
    <div
      className={isAnnouncementBannerVisible ? extraTopPaddingContainerClass : containerClass}
      ref={ref}
    >
      <div className={headerContainerClass}>
        <h1 className={headerTextClass}>
          The best prices <span className={headerTextAltClass}>on over 9 million tokens</span>
        </h1>

        <Link
          href={{
            pathname: routes.TOKEN_PROFILE,
            query,
          }}
          onClick={() =>
            logEvent({
              name: EVENT_NAME.START_TRADING_CLICKED,
              properties: query,
            })
          }
        >
          <div className={buttonClass}>Start Trading</div>
        </Link>
      </div>

      <GreenArchImage className={greenArchClass} />
      <YellowCircleImage className={yellowCircleClass} />
    </div>
  );
});
