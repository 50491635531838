import { Address } from 'viem';

import { isTokenAddressNativeAsset } from '@/utils/multichain';

import {
  DAI_ADDRESSES,
  ETH_FAKE_ADDRESS,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID,
  USDC_ADDRESSES,
  USDT_ADDRESSES,
  WBTC_ADDRESSES,
  WETH_ADDRESSES,
  WRAPPED_NATIVE_ADDRESSES,
} from '../constants/addresses';
import { CHAIN_IDS, NETWORK_NAME_PER_CHAIN_ID } from '../constants/chain';

// Ethereum Mainnet
const wstETH = '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0';
const stETH = '0xae7ab96520de3a18e5e111b5eaab095312d7fe84';
const weETH = '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee';
const pepe = '0x6982508145454ce325ddbe47a25d4ec3d2311933';

// Arbitrum
const ARB = '0x912ce59144191c1204e64559fe8253a0e49e6548';
const GMX = '0xfc5a1a6eb076a2c7ad06ed22c90d7e710e35ad0a';
const ezETH = '0x2416092f143378750bb29b79ed961ab195cceea5';
const arbWstETH = '0x5979d7b546e38e414f7e9822514be443a4800529';

// Optimism
const OP = '0x4200000000000000000000000000000000000042';
const VELO = '0x3c8b650257cfb5f272f799f5e2b4e65093a11a05';
const sUSD = '0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9';
const opWstETH = '0x1f32b1c2345538c0c6f582fcb022739c4a194ebb';
const snx = '0x8700daec35af8ff88c16bdf0418774cb3d7599b4';

// Polygon
const sure = '0xf88332547c680f755481bf489d890426248bb275';
const stMatic = '0x3a58a54c066fdc0f2d55fc9c89f0415c92ebf3c4';
const sol = '0xd93f7e271cb87c23aaa73edc008a79646d1f9912';

// Base
const cbBTC = '0xcbb7c0000ab88b473b1f5afd9ef808440eed33bf';
const cbETH = '0x2ae3f1ec7f1f5012cfeab0185bfc7aa3cf0dec22';
const rETH = '0xb6fe221fe9eef5aba221c348ba20a1bf5e73624c';
const aero = '0x940181a94a35a4569e4529a3cdfb74e38fd98631';
const brett = '0x532f27101965dd16442e59d40670faf5ebb142e4';
const baseWstETH = '0xc1cba3fcea344f92d9239c08c0568f6f2f0ee452';

// BNN Chain
const BUSD = '0xe9e7cea3dedca5984780bafc599bd69add087d56';
const CAKE = '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82';
const btcb = '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c';
const fdusd = '0xc5f0f7b66764f6ec8c8dff7ba683102295e16409';
const doge = '0xba2ae424d960c26247dd6c32edc70b295c744c43';

// Avalanche
const sAVAX = '0x2b2c81e08f1af8835a78bb2a90ae924ace0ea4be';
const BTCb = '0x152b9d0fdc40c096757f570a51e494bd4b943e50';
const JOE = '0x6e84a6216ea6dacc71ee8e6b0a5b7322eebc0fdd';
const WETHe = '0x49d5c2bdffac6ce2bfdB6640F4F80f226bc10bab';
const ampl = '0x027dbca046ca156de9622cd1e2d907d375e53aa7';

// Scroll
const scr = '0xd29687c813d741e2f938f4ac377128810e217b1b';
const stone = '0x80137510979822322193fc997d400d5a6c747bf7';
const scrollWstETH = '0xf610a9dfb7c89644979b4a0f27063e9e7d7cda32';
const scrollWeETH = '0x01f0a31698c4d065659b9bdc21b3610292a1c506';

// Blast
const USDB = '0x4300000000000000000000000000000000000003';
const BLAST = '0xb1a5700fa2358173fe465e6ea4ff52e36e88e2ad';
const blastEzEth = '0x2416092f143378750bb29b79ed961ab195cceea5';
const thrust = '0xe36072dd051ce26261bf50cd966311cab62c596e';
const axlUSDC = '0xeb466342c4d449bc9f53a865d5cb90586f405215';
const hyper = '0xec73284e4ec9bcea1a7dddf489eaa324c3f7dd31';
const sfrxETH = '0x1f55a02a049033e3419a8e2975cf3f572f4e6e9a';

// Linea
const lineaEzETH = '0x2416092f143378750bb29b79ed961ab195cceea5';
const lineaWstETH = '0xb5bedd42000b71fdde22d3ee8a79bd49a568fc8f';
const lineaStone = '0x93f4d0ab6a8b4271f4a28db399b5e30612d21116';
const lineaWeETH = '0x1bf74c010e6320bab11e2e5a532b5ac15e0b8aa6';

// Mantle
const mantlemETH = '0xcDA86A272531e8640cD7F1a92c01839911B90bb0';
const mantleENA = '0x58538e6a46e07434d7e7375bc268d3cb839c0133';
const mantleUSDe = '0x5d3a1ff2b6bab83b63cd9ad0787074081a52ef34';
const mantleJOE = '0x371c7ec6d8039ff7933a2aa28eb827ffe1f52f07';
const mantleMOE = '0x4515a45337f461a11ff0fe8abf3c606ae5dc00c9';

// Unichain
const unichainUniswap = '0x8f187aa05619a017077f5308904739877ce9ea21';

// This list is hardcoded using the table in the PRD
// https://www.notion.so/zeroex/PRD-Matcha-Homepage-222ea10ced9d4519aeb14ec4436f007d?pvs=4#85609626478e443b873db46a7d7eca81

const mainNetTokens = [
  WETH_ADDRESSES[CHAIN_IDS.MAINNET],
  USDC_ADDRESSES[CHAIN_IDS.MAINNET],
  USDT_ADDRESSES[CHAIN_IDS.MAINNET],
  DAI_ADDRESSES[CHAIN_IDS.MAINNET],
  WBTC_ADDRESSES[CHAIN_IDS.MAINNET],
  wstETH,
  weETH,
  stETH,
  pepe,
  ETH_FAKE_ADDRESS,
];

const arbitrumTokens = [
  WETH_ADDRESSES[CHAIN_IDS.ARBITRUM],
  USDC_ADDRESSES[CHAIN_IDS.ARBITRUM],
  ARB,
  USDT_ADDRESSES[CHAIN_IDS.ARBITRUM],
  WBTC_ADDRESSES[CHAIN_IDS.ARBITRUM],
  arbWstETH,
  ezETH,
  GMX,
  DAI_ADDRESSES[CHAIN_IDS.ARBITRUM],
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.ARBITRUM],
];

const optimismTokens = [
  WETH_ADDRESSES[CHAIN_IDS.OPTIMISM],
  USDC_ADDRESSES[CHAIN_IDS.OPTIMISM],
  OP,
  opWstETH,
  USDT_ADDRESSES[CHAIN_IDS.OPTIMISM],
  VELO,
  sUSD,
  DAI_ADDRESSES[CHAIN_IDS.OPTIMISM],
  snx,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.OPTIMISM],
];

const maticTokens = [
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.MATIC],
  USDC_ADDRESSES[CHAIN_IDS.MATIC],
  USDT_ADDRESSES[CHAIN_IDS.MATIC],
  WETH_ADDRESSES[CHAIN_IDS.MATIC],
  WBTC_ADDRESSES[CHAIN_IDS.MATIC],
  DAI_ADDRESSES[CHAIN_IDS.MATIC],
  sure,
  stMatic,
  sol,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.MATIC],
];

const baseTokens = [
  WETH_ADDRESSES[CHAIN_IDS.BASE],
  USDC_ADDRESSES[CHAIN_IDS.BASE],
  cbBTC,
  DAI_ADDRESSES[CHAIN_IDS.BASE],
  cbETH,
  rETH,
  aero,
  brett,
  baseWstETH,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.BASE],
];

const bscTokens = [
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.BSC],
  USDT_ADDRESSES[CHAIN_IDS.BSC],
  USDC_ADDRESSES[CHAIN_IDS.BSC],
  BUSD,
  DAI_ADDRESSES[CHAIN_IDS.BSC],
  CAKE,
  btcb,
  fdusd,
  doge,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.BSC],
];

const avalancheTokens = [
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.AVALANCHE],
  USDC_ADDRESSES[CHAIN_IDS.AVALANCHE],
  USDT_ADDRESSES[CHAIN_IDS.AVALANCHE],
  sAVAX,
  BTCb,
  JOE,
  DAI_ADDRESSES[CHAIN_IDS.AVALANCHE],
  WETHe,
  ampl,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.AVALANCHE],
];

const scrollTokens = [
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.SCROLL],
  USDC_ADDRESSES[CHAIN_IDS.SCROLL],
  DAI_ADDRESSES[CHAIN_IDS.SCROLL],
  WBTC_ADDRESSES[CHAIN_IDS.SCROLL],
  USDT_ADDRESSES[CHAIN_IDS.SCROLL],
  scr,
  stone,
  scrollWstETH,
  scrollWeETH,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.SCROLL],
];

const blastTokens = [
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.BLAST],
  USDB,
  WBTC_ADDRESSES[CHAIN_IDS.BLAST],
  BLAST,
  blastEzEth,
  thrust,
  axlUSDC,
  hyper,
  sfrxETH,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.BLAST],
];

const lineaTokens = [
  USDC_ADDRESSES[CHAIN_IDS.LINEA],
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.LINEA],
  USDT_ADDRESSES[CHAIN_IDS.LINEA],
  WBTC_ADDRESSES[CHAIN_IDS.LINEA],
  DAI_ADDRESSES[CHAIN_IDS.LINEA],
  lineaEzETH,
  lineaWstETH,
  lineaStone,
  lineaWeETH,
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.LINEA],
];

const mantleTokens = [
  USDC_ADDRESSES[CHAIN_IDS.MANTLE],
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.MANTLE],
  WETH_ADDRESSES[CHAIN_IDS.MANTLE],
  USDT_ADDRESSES[CHAIN_IDS.MANTLE],
  WBTC_ADDRESSES[CHAIN_IDS.MANTLE],
  mantlemETH,
  mantleUSDe,
  mantleENA,
  mantleJOE,
  mantleMOE,
];

const unichainTokens = [
  WETH_ADDRESSES[CHAIN_IDS.UNICHAIN],
  USDC_ADDRESSES[CHAIN_IDS.UNICHAIN],
  DAI_ADDRESSES[CHAIN_IDS.UNICHAIN],
  unichainUniswap,
];

const solanaTokens = [
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.SOLANA],
  USDC_ADDRESSES[CHAIN_IDS.SOLANA],
  USDT_ADDRESSES[CHAIN_IDS.SOLANA],
  WBTC_ADDRESSES[CHAIN_IDS.SOLANA],
];

const modeTokens = [
  USDC_ADDRESSES[CHAIN_IDS.MODE],
  WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.MODE],
  USDT_ADDRESSES[CHAIN_IDS.MODE],
  NATIVE_TOKEN_ADDRESS_PER_CHAIN_ID[CHAIN_IDS.MODE],
  '0xDfc7C877a950e49D2610114102175A06C2e3167a', // Mode
  '0x2416092f143378750bb29b79eD961ab195CcEea5', // ezETH
  '0x6863fb62Ed27A9DdF458105B507C15b5d741d62e', // Kim
  '0x59889b7021243dB5B1e065385F918316cD90D46c', // MBTC
];

export type Token = {
  address: Address;
  chainId: number;
  logo?: string;
  color?: string;
  symbol?: string;
  name?: string;
  priceUSD: number;
  percentageChange24: number;
};
export type PopularToken = {
  address: string;
  chainId: number;
  // if native asset, we need wrapped address for price from defined.
  addressForPriceData?: string;
  logo?: string | null;
  symbol?: string;
  name?: string;
  currentPrice?: number | null;
  percentageChangeUnformatted?: number;
};

type MostPopularTokensByFilterName = Record<string | 'All Networks', PopularToken[]>;

function getTokenData(address: string, chainId: number) {
  return {
    address,
    chainId: chainId,
    addressForPriceData: isTokenAddressNativeAsset(address, chainId)
      ? WRAPPED_NATIVE_ADDRESSES[chainId]
      : undefined,
  };
}

/**
 * Most Popular tokens for the Landing Page. Filtered
 * by the string of every supported chain and "All Networks".
 */
export const mostPopularTokensByFilterName: MostPopularTokensByFilterName = {
  'All networks': [
    {
      address: WETH_ADDRESSES[CHAIN_IDS.MAINNET],
      chainId: CHAIN_IDS.MAINNET,
    },
    {
      address: USDC_ADDRESSES[CHAIN_IDS.MAINNET],
      chainId: CHAIN_IDS.MAINNET,
    },
    {
      address: USDT_ADDRESSES[CHAIN_IDS.MAINNET],
      chainId: CHAIN_IDS.MAINNET,
    },
    {
      address: DAI_ADDRESSES[CHAIN_IDS.MAINNET],
      chainId: CHAIN_IDS.MAINNET,
    },
    {
      address: WBTC_ADDRESSES[CHAIN_IDS.MAINNET],
      chainId: CHAIN_IDS.MAINNET,
    },
    { address: WRAPPED_NATIVE_ADDRESSES[CHAIN_IDS.BSC], chainId: CHAIN_IDS.BSC },
    { address: weETH, chainId: CHAIN_IDS.MAINNET },
    { address: '0xf88332547c680f755481bf489d890426248bb275', chainId: CHAIN_IDS.MATIC },
    { address: ARB, chainId: CHAIN_IDS.ARBITRUM },
    { address: '0x6982508145454ce325ddbe47a25d4ec3d2311933', chainId: CHAIN_IDS.MAINNET },
  ],
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.MAINNET]]: mainNetTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.MAINNET),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.MATIC]]: maticTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.MATIC),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.ARBITRUM]]: arbitrumTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.ARBITRUM),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.BASE]]: baseTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.BASE),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.OPTIMISM]]: optimismTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.OPTIMISM),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.BSC]]: bscTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.BSC),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.AVALANCHE]]: avalancheTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.AVALANCHE),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.SCROLL]]: scrollTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.SCROLL),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.BLAST]]: blastTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.BLAST),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.LINEA]]: lineaTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.LINEA),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.MANTLE]]: mantleTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.MANTLE),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.MODE]]: modeTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.MODE),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.UNICHAIN]]: unichainTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.UNICHAIN),
  ),
  [NETWORK_NAME_PER_CHAIN_ID[CHAIN_IDS.SOLANA]]: solanaTokens.map((address) =>
    getTokenData(address, CHAIN_IDS.SOLANA),
  ),
};
