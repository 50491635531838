import { SerializableResult } from '@/types';
import { chunk } from 'lodash';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import router from 'next/router';
import { ReactNode, useMemo, useState } from 'react';
import { useMedia } from 'react-use';

import * as PaginatedHorizontalScroll from '@/components/NewLanding/PaginatedHorizontalScroll';
import { Axis } from '@/components/Charts/Axis';
import ChartHeaderCard from '@/components/Charts/LineChartWithTooltip/ChartHeaderCard';
import {
  CHART_RANGE_MINUTES,
  TIME_FRAMES,
  TokenPrice,
  filterChartData,
  getPercentageChange,
} from '@/components/Charts/utils';
import { ErrorFallback } from '@/components/NewLanding/ErrorFallback';
import { TokenCard } from '@/components/NewLanding/TokenCard';

import {
  CHAIN_ID_PER_NETWORK_URL_PATH,
  NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID,
  NETWORK_NAME_PER_CHAIN_ID,
  solana,
  SUPPORTED_CHAIN_IDS,
} from '@/constants/chain';

import { Token, mostPopularTokensByFilterName } from '@/data/tokens';

import { useHeroColor } from '@/hooks/homepage/useHeroColor';
import { SOLANA_PHASE1_COOKIE_KEY, useFeature } from '@/hooks/useFeature';
import { useMeasure } from '@/hooks/useMeasure';
// data
import { useTimeSeries } from '@/hooks/useTimeSeries';

import { breakpoints } from '@/styles/util';

import { ChainIcons } from '@/ui/Icons/TokenChainIcon';
import { Select } from '@/ui/Select';
import Tooltip from '@/ui/Tooltip';

import { GetTimeSeriesResponse } from '@/utils/defined.types';
// utils
import { routes } from '@/utils/routes';

import { ChevronDownIcon } from '../../../ui/Icons';
import {
  HOMEPAGE_CLICK_PAGINATION_PROPS,
  HOMEPAGE_NETWORK_SELECT_PROPS,
  logEvent,
} from '../../../utils/amplitude';
import { classNames } from '../../../utils/classnames';
import { HorizontalSelection } from '../HorizontalSelection';
import MobileSwipePagination from '../MobileSwipePagination';
import { MobileTokenCard } from '../MobileTokenCard';
import { TokensByChainName } from '../types';
import {
  mostPopularTokensContainer,
  staticChartImageContainerClass,
  headerContainerClass,
  trendingTokensRow,
  networkFilterClass,
  mostPopularTokensDataContainer,
  axisClass,
  STATIC_LINE_CHART_WIDTH,
  STATIC_LINE_CHART_HEIGHT,
  tradeCardClass,
  selectWrapperClass,
  labelClass,
  bgGray90,
  visibleOnMobile,
  visibleOnTablet,
  selectIconClass,
  mobileSwipePaginationRootClass,
  mobileTokenCardClass,
  disclaimerClass,
  iconPreviousClass,
  buttonClass,
  iconNextClass,
  paginationButtonsClass,
  filterAndPaginationClass,
  verticalSpacerClass,
  trendingTokensRegularPage,
  headlessLabelClass,
} from './index.css';

const DeferredLineChartWithTooltip = dynamic(() => import('../../Charts/LineChartWithTooltip'), {
  ssr: false,
  loading: () => {
    return (
      <Image
        src={`/api/charts/static/?tokenAddress=${
          mostPopularTokensByFilterName['All networks'].at(0)?.address
        }&chainId=${mostPopularTokensByFilterName['All networks'].at(0)?.chainId}`}
        width={STATIC_LINE_CHART_WIDTH.desktop}
        height={STATIC_LINE_CHART_HEIGHT}
        alt="weth chart"
      />
    );
  },
});

const ORDERED_FILTER_LIST: (string | number)[] = ['All networks', ...SUPPORTED_CHAIN_IDS];
const ORDERED_FILTER_LIST_WITH_SOLANA: (string | number)[] = [
  'All networks',
  solana.id,
  ...SUPPORTED_CHAIN_IDS,
];

const minPage = 0;
const maxPage = 2;

function chunkWithFrontpage(tokens: Token[]) {
  return {
    // first page has 1 big card and 6 small cards
    desktop: [
      tokens.slice(0, 7),
      // pages after that have 10 cards each
      ...chunk(tokens.slice(7), 10),
    ],
    // for mobile, we show 5 cards per page (max 3 pages)
    mobile: chunk(tokens.slice(0, 15), 5),
  };
}

export function TrendingTokens({
  trendingTokensByFilter,
}: {
  trendingTokensByFilter: TokensByChainName;
}) {
  const isSolanaPhase1 = useFeature(SOLANA_PHASE1_COOKIE_KEY);
  const [selectedFilter, setSelectedFilter] = useState('All networks');
  const isDesktop = useMedia(breakpoints.desktop, false);
  const [tooltipData, setToolTipData] = useState<TokenPrice | undefined>(undefined);
  const { setCurrentColor } = useHeroColor();
  const [currentPage, setCurrentPage] = useState(0);
  const [firstPageHeight, setFirstPageHeight] = useState<null | number>(null);
  const chunkedTrendingTokens = useMemo(() => {
    let out: Record<string, SerializableResult<Record<'desktop' | 'mobile', Token[][]>>> = {};
    for (const [key, value] of Object.entries(trendingTokensByFilter)) {
      if (value.type === 'success') {
        const data = isSolanaPhase1
          ? chunkWithFrontpage(value.data)
          : chunkWithFrontpage(value.data.filter((token) => token.chainId !== solana.id));
        out[key] = {
          type: 'success',
          data,
        };
      } else {
        out[key] = value;
      }
    }
    return out;
  }, [trendingTokensByFilter, isSolanaPhase1]);

  const initialSelectedItems = chunkedTrendingTokens['All networks'];

  const [latestSelectedItems, setSelectedItems] = useState<SerializableResult<
    Record<'desktop' | 'mobile', Token[][]>
  > | null>(null);

  const selectedItems = latestSelectedItems ?? initialSelectedItems;

  const onSelectFilter = (filter: string) => {
    setSelectedFilter(filter);
    setSelectedItems(chunkedTrendingTokens[filter]);
    logEvent({
      name: 'homepage_network_select',
      properties: {
        filter,
        tokenCardType: 'trending',
      } as HOMEPAGE_NETWORK_SELECT_PROPS,
    });
    setCurrentPage(0);
  };

  const mainChartImgTrendingToken = useMemo(() => {
    return selectedItems.type === 'success' ? selectedItems.data.desktop[0].at(0) : undefined;
  }, [selectedItems]);

  const onPagination = (newPage: number) => {
    logEvent({
      name: 'homepage_click_pagination',
      properties: {
        filter: selectedFilter,
        tokenCardType: 'trending',
        paginationDirection: currentPage > newPage ? 'left' : 'right',
      } as HOMEPAGE_CLICK_PAGINATION_PROPS,
    });
    setCurrentPage(newPage);
  };

  const mobileNetworkFilters = useMemo(() => {
    const list = isSolanaPhase1 ? ORDERED_FILTER_LIST_WITH_SOLANA : ORDERED_FILTER_LIST;
    return list.map((filterName) => {
      const id =
        typeof filterName === 'number' ? filterName : CHAIN_ID_PER_NETWORK_URL_PATH[filterName];

      const label =
        typeof filterName === 'number' ? NETWORK_NAME_PER_CHAIN_ID[filterName] : filterName;

      const icon = !!id ? (
        <Image
          src={ChainIcons[id]}
          className={selectIconClass}
          alt={`${label} logo`}
          height={16}
          width={16}
        />
      ) : null;
      return {
        label: (
          <div className={headlessLabelClass}>
            {icon}
            {<span className={icon ? visibleOnMobile : undefined}>{label}</span>}
          </div>
        ),
        value: label,
      };
    });
  }, [isSolanaPhase1]);
  const networkFilters = useMemo(() => {
    const list = isSolanaPhase1 ? ORDERED_FILTER_LIST_WITH_SOLANA : ORDERED_FILTER_LIST;
    return list.map((filterName) => {
      const id =
        typeof filterName === 'number' ? filterName : CHAIN_ID_PER_NETWORK_URL_PATH[filterName];
      const label = typeof filterName === 'number' ? NETWORK_NAME_PER_CHAIN_ID[filterName] : 'All';
      const value =
        typeof filterName === 'number' ? NETWORK_NAME_PER_CHAIN_ID[filterName] : filterName;

      const icon = !!id ? (
        <Image
          src={ChainIcons[id]}
          className={selectIconClass}
          alt={`${label} logo`}
          height={16}
          width={16}
        />
      ) : null;
      return {
        label: (
          <Tooltip.Provider>
            <Tooltip.Root>
              <Tooltip.Trigger>
                <div className={labelClass}>
                  {icon}
                  {<span className={icon ? visibleOnMobile : undefined}>{label}</span>}
                </div>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content>{label}</Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        ),
        value,
      };
    });
  }, [isSolanaPhase1]);

  /**
   * Using time series response data to power a responsive axis on the client side.
   * If successful, we set the tokenPrices state which is then passed to <Axis/>
   */
  const [tokenPrices, setTokenPrices] = useState<TokenPrice[] | undefined>(undefined);
  const onTimeSeriesSuccess = (response: GetTimeSeriesResponse) => {
    if (response['s'] === 'ok') {
      setTokenPrices(filterChartData(response));
    }
  };
  useTimeSeries({
    address: mainChartImgTrendingToken?.address,
    chainId: mainChartImgTrendingToken?.chainId,
    currencyCode: 'USD',
    resolution: TIME_FRAMES.FIVE_MINUTES,
    chartRangeInMinutes: CHART_RANGE_MINUTES.DAILY,
    removeLeadingNullValues: true,
    swrOptions: { refreshInterval: 0, onSuccess: onTimeSeriesSuccess },
  });
  // The chart requires a static width value, so we calculate the width of the parent first
  const { bounds: tokenChartBounds, nodeRef: tokenChartRef } = useMeasure<HTMLDivElement>();
  const chartWidth = isDesktop
    ? Math.min(tokenChartBounds.width, STATIC_LINE_CHART_WIDTH.desktop)
    : Math.max(tokenChartBounds.width, STATIC_LINE_CHART_WIDTH.tablet);

  const percentChange = useMemo(() => {
    return tokenPrices ? getPercentageChange(tokenPrices) : undefined;
  }, [tokenPrices]);

  return (
    <div className={mostPopularTokensContainer}>
      <div className={headerContainerClass}>
        <h1 className={networkFilterClass}>
          Trending tokens<span className={disclaimerClass}>*</span>
        </h1>
        <div className={filterAndPaginationClass}>
          <div className={classNames([selectWrapperClass, bgGray90])}>
            <div className={visibleOnMobile}>
              <Select
                placeholderText="Select a network"
                selected={selectedFilter}
                disabled={selectedItems.type === 'error'}
                onSelect={onSelectFilter}
                items={mobileNetworkFilters}
                variant="secondary"
              />
            </div>
            <div className={visibleOnTablet}>
              <HorizontalSelection
                items={networkFilters}
                onSelect={onSelectFilter}
                selected={selectedFilter}
              />
            </div>
          </div>
          <div className={verticalSpacerClass} />
          <div className={paginationButtonsClass}>
            <button
              aria-label="previous page"
              className={buttonClass}
              disabled={selectedItems.type === 'error' || currentPage === minPage}
              onClick={() => onPagination(Math.max(minPage, currentPage - 1))}
            >
              <ChevronDownIcon className={iconPreviousClass} />
            </button>
            <button
              aria-label="next page"
              className={buttonClass}
              disabled={
                selectedItems.type === 'error' ||
                currentPage === maxPage ||
                selectedItems.data.desktop.length <= currentPage + 1
              }
              onClick={() => onPagination(Math.min(maxPage, currentPage + 1))}
            >
              <ChevronDownIcon className={iconNextClass} />
            </button>
          </div>
        </div>
      </div>
      {selectedItems.type === 'error' ||
        (!mainChartImgTrendingToken && (
          <div style={{ height: isDesktop ? '348px' : '210px' }}>
            <ErrorFallback componentName="the trending tokens" />
          </div>
        ))}
      {selectedItems.type === 'success' && mainChartImgTrendingToken && (
        <>
          <MobileSwipePagination.Root className={mobileSwipePaginationRootClass}>
            <MobileSwipePagination.Pages>
              {selectedItems.data.mobile.map((pageItems, pageIndex) => (
                <MobileSwipePagination.Page key={pageIndex}>
                  {pageItems.map((item) => (
                    <div key={item.address} style={{ marginBottom: '10px' }}>
                      <MobileTokenCard
                        loading={false}
                        tokenAddress={item.address}
                        chainId={item.chainId}
                        tokenName={item.name ?? ''}
                        tokenSymbol={item.symbol ?? ''}
                        tokenLogoUrl={item.logo}
                        percentageChange={item.percentageChange24 ?? 0}
                        usdValue={item.priceUSD ?? 0}
                        className={mobileTokenCardClass}
                      />
                    </div>
                  ))}
                </MobileSwipePagination.Page>
              ))}
            </MobileSwipePagination.Pages>
            <MobileSwipePagination.PageIndicator />
          </MobileSwipePagination.Root>

          <PaginatedHorizontalScroll.Root
            columns={5}
            isHorizontalScroll={false}
            page={currentPage + 1}
            className={visibleOnTablet}
          >
            <PaginatedHorizontalScroll.List
              ref={(el) => {
                if (el) {
                  setFirstPageHeight(el.clientHeight);
                }
              }}
              pages={[
                (isActive: boolean) => (
                  <div
                    key={0}
                    className={mostPopularTokensDataContainer}
                    ref={tokenChartRef}
                    data-active={isActive}
                  >
                    {selectedItems.type === 'success' &&
                      mainChartImgTrendingToken &&
                      currentPage === 0 && (
                        <div
                          className={staticChartImageContainerClass}
                          role="link"
                          onMouseEnter={() =>
                            mainChartImgTrendingToken.color &&
                            setCurrentColor(mainChartImgTrendingToken.color)
                          }
                          onMouseLeave={() => setCurrentColor(null)}
                          onClick={() =>
                            router.push({
                              pathname: routes.TOKEN_PROFILE,
                              query: {
                                chainId:
                                  NETWORK_NAME_FOR_URLPATH_PER_CHAIN_ID[
                                    mainChartImgTrendingToken.chainId
                                  ],
                                contractAddresses: [mainChartImgTrendingToken.address],
                              },
                            })
                          }
                        >
                          <ChartHeaderCard
                            mostPopularTokenInfo={mainChartImgTrendingToken}
                            tooltipData={tooltipData}
                            percentChangeFormatted={percentChange}
                          />
                          <DeferredLineChartWithTooltip
                            tokenData={tokenPrices || []}
                            resolution={TIME_FRAMES.FIVE_MINUTES}
                            width={chartWidth}
                            height={STATIC_LINE_CHART_HEIGHT}
                            tokenViewPairActive={false}
                            variant="home-page"
                            setTooltipData={setToolTipData}
                          />
                          <span className={axisClass}>
                            {tokenPrices && (
                              <Axis
                                tokenData={tokenPrices}
                                range={CHART_RANGE_MINUTES.DAILY}
                                className={axisClass}
                              />
                            )}
                          </span>
                        </div>
                      )}
                    <div className={trendingTokensRow}>
                      {selectedItems.data.desktop[0].slice(1).map((token) => (
                        <TokenCard
                          key={`${token.chainId}_${token.address}`}
                          chainId={token.chainId}
                          percentageChange={token.percentageChange24 ?? 0}
                          tokenAddress={token.address}
                          tokenName={token.name ?? ''}
                          tokenSymbol={token.symbol ?? ''}
                          usdValue={token.priceUSD ?? 0}
                          loading={token === undefined ? true : false}
                          tokenLogoUrl={token.logo || undefined}
                          type={'trending'}
                          tokenColor={token.color}
                          className={tradeCardClass}
                        />
                      ))}
                    </div>
                  </div>
                ),
                ...selectedItems.data.desktop.slice(1).map(
                  (page, pageIndex) =>
                    ((isActive: boolean) => (
                      <div
                        key={pageIndex}
                        className={trendingTokensRegularPage}
                        style={{ minHeight: firstPageHeight ?? undefined }}
                        data-height={firstPageHeight}
                        data-active={isActive}
                      >
                        {page.map((token) => (
                          <TokenCard
                            key={`${token.chainId}_${token.address}`}
                            chainId={token.chainId}
                            percentageChange={token.percentageChange24 ?? 0}
                            tokenAddress={token.address}
                            tokenName={token.name ?? ''}
                            tokenSymbol={token.symbol ?? ''}
                            usdValue={token.priceUSD ?? 0}
                            loading={token === undefined ? true : false}
                            tokenLogoUrl={token.logo || undefined}
                            type={'trending'}
                            tokenColor={token.color}
                            className={tradeCardClass}
                          />
                        ))}
                      </div>
                    )) as (isActive: boolean) => ReactNode,
                ),
              ]}
            />
          </PaginatedHorizontalScroll.Root>
        </>
      )}
    </div>
  );
}
